import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Text, Grommet, ResponsiveContext, Avatar, Button } from 'grommet';
import { getCurrentWeekScoreboard } from '../espnReader';
import { totalScore, projectedPoints } from '../util';

// Grommet Theme (optional, for customizing the look)
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
};

const LeagueScoreboardByScore = ({ leagueId, season }) => {
  const [teamsList, setTeamsList] = useState([]);
  const [sortType, setSortType] = useState('total'); // State for sorting by total or projected
  const size = useContext(ResponsiveContext); // Get screen size

  useEffect(() => {
    // Fetch the current week's matchups with team info
    const fetchMatchups = async () => {
      const games = await getCurrentWeekScoreboard(leagueId, season);
      
      // Collect all teams from both home and away sides
      let allTeams = [];
      games.forEach(game => {
        allTeams.push({
          teamId: game.home.teamId,
          teamName: game.home.teamName,
          teamLogo: game.home.teamLogo,
          totalPoints: Math.round(totalScore(game.home) * 100) / 100,
          projectedPoints: Math.round(projectedPoints(game.home) * 100) / 100
        });
        allTeams.push({
          teamId: game.away.teamId,
          teamName: game.away.teamName,
          teamLogo: game.away.teamLogo,
          totalPoints: Math.round(totalScore(game.away) * 100) / 100,
          projectedPoints: Math.round(projectedPoints(game.away) * 100) / 100
        });
      });

      // Sort teams based on the default sorting criteria (total points)
      sortTeams(allTeams, 'total');
    };
    fetchMatchups();
  }, [leagueId, season]);

  // Sorting function based on sortType ('total' or 'projected')
  const sortTeams = (teams, sortType) => {
    const sortedTeams = [...teams].sort((a, b) => {
      if (sortType === 'total') {
        return b.totalPoints - a.totalPoints;
      } else if (sortType === 'projected') {
        return b.projectedPoints - a.projectedPoints;
      }
      return 0;
    });
    setTeamsList(sortedTeams);
  };

  // Toggle sorting type and re-sort
  const toggleSortType = () => {
    const newSortType = sortType === 'total' ? 'projected' : 'total';
    setSortType(newSortType);
    sortTeams(teamsList, newSortType);
  };

  // Determine if the layout should be 1-column or 2-column based on screen size
  const isSmallScreen = size === 'small' || size === 'xsmall';

  return (
    <Grommet theme={theme}>
      <Box pad="small">
        {/* Button to toggle sorting */}
        <Box direction="row" justify="end" align="center">
          <Button
            label={`Sort by ${sortType === 'total' ? 'Projected Points' : 'Total Points'}`}
            onClick={toggleSortType}
            size="small" // Make the button smaller
            margin={{ bottom: 'medium' }} // Margin to add space below
            alignSelf="end" // Align the button to the right side
          />
        </Box>

        {teamsList.length > 0 ? (
          <Grid
            rows={isSmallScreen ? 'auto' : 'small'} // Reduced row height for smaller, tighter boxes
            columns={isSmallScreen ? '100%' : ['1/2', '1/2']}
            gap="small" // Reduced gap to tighten the layout
            responsive
          >
            {teamsList.map((team, index) => (
              <Box
                key={team.teamId}
                border={{ color: 'brand', size: 'small' }}
                round="small"
                pad="small"
                background="light-2"
              >
                <Grid
                  rows={['xsmall']}
                  columns={['auto', 'flex']}
                  gap="small"
                  areas={[
                    { name: 'teamInfo', start: [0, 0], end: [1, 0] },
                  ]}
                >
                  {/* Team Info */}
                  <Box gridArea="teamInfo" direction="row" align="center" gap="small">
                    <Avatar src={team.teamLogo} alt={team.teamName} size='small' />
                    <Box>
                      <Text>{team.teamName}</Text>
                      <Text size="large" weight="bold">
                        {team.totalPoints} (proj: {team.projectedPoints})
                      </Text>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        ) : (
          <Text>Loading teams...</Text>
        )}
      </Box>
    </Grommet>
  );
};

export default LeagueScoreboardByScore;