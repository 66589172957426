import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Text, Grommet, ResponsiveContext, Avatar } from 'grommet';
import { getCurrentWeekScoreboard } from '../espnReader';
import { totalScore } from '../util';

// Grommet Theme (optional, for customizing the look)
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
};

const LeagueScoreboard = ({ leagueId, season }) => {
  const [matchups, setMatchups] = useState([]);
  const size = useContext(ResponsiveContext); // Get screen size (small, medium, large, etc.)

  useEffect(() => {
    // Fetch the current week's matchups with team info
    const fetchMatchups = async () => {
      const games = await getCurrentWeekScoreboard(leagueId, season);
      setMatchups(games);
    };
    fetchMatchups();
  }, [leagueId, season]);

  // Determine if we should use the compact layout (for small screens)
  const isSmallScreen = size === 'small' || size === 'xsmall';

  return (
    <Grommet theme={theme}>
      <Box pad={isSmallScreen ? 'auto' : 'small'}>
        {matchups.length > 0 ? (
          <Grid
            rows={isSmallScreen ? 'auto' : 'small'} // Use compact rows for small screens
            columns={isSmallScreen ? '100%' : ['1/2', '1/2']}
            gap={isSmallScreen ? 'small' : 'medium'} // Adjust gap based on screen size
            responsive
          >
            {matchups.map((game, index) => (
              <Box
                key={game.id}
                border={{ color: 'brand', size: 'small' }}
                round="small"
                pad={isSmallScreen ? { vertical: 'xsmall', horizontal: 'small' } : { vertical: 'small', horizontal: 'medium' }} // Conditionally adjust padding
                background="light-2"
              >
                <Grid
                  rows={isSmallScreen ? ['xxsmall', 'xxsmall'] : ['small', 'small']} // Adjust row height based on screen size
                  columns={['1/2', '1/2']}
                  gap={isSmallScreen ? 'xsmall' : 'medium'} // Adjust gap between home and away teams
                  areas={[
                    { name: 'awayTeam', start: [0, 0], end: [1, 0] },
                    { name: 'homeTeam', start: [0, 1], end: [1, 1] },
                  ]}
                >
                  {/* Away Team */}
                  <Box gridArea="awayTeam" direction="row" align="center" gap="small">
                    <Avatar src={game.away.teamLogo} alt={game.away.teamName} size={isSmallScreen ? 'small' : 'medium'} /> {/* Adjust avatar size */}
                    <Box>
                      <Text size={isSmallScreen ? 'medium' : 'large'}>{game.away.teamName}</Text> {/* Adjust text size */}
                      <Text size={isSmallScreen ? 'medium' : 'large'} weight="bold">
                        {Math.round(totalScore(game.away) * 100) / 100}
                      </Text>
                    </Box>
                  </Box>

                  {/* Home Team */}
                  <Box gridArea="homeTeam" direction="row" align="center" gap="small">
                    <Avatar src={game.home.teamLogo} alt={game.home.teamName} size={isSmallScreen ? 'small' : 'medium'} /> {/* Adjust avatar size */}
                    <Box>
                      <Text size={isSmallScreen ? 'medium' : 'large'}>{game.home.teamName}</Text> {/* Adjust text size */}
                      <Text size={isSmallScreen ? 'medium' : 'large'} weight="bold">
                        {Math.round(totalScore(game.home) * 100) / 100}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        ) : (
          <Text>Loading matchups...</Text>
        )}
      </Box>
    </Grommet>
  );
};

export default LeagueScoreboard;