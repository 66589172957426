import {
    Avatar,
    CardHeader,
    Card,
    Box,  
    Text,
    CardBody
} from 'grommet';
import { useEffect, useState } from 'react';
import RosterTable from './RosterTable';

import { getCurrentBoxscore } from './espnReader';

export default function LeagueCard(props) {
    // const classes = useStyles();
    const [teamInfo, setTeamInfo] = useState({});
    const [matchupStats, setMatchupStats] = useState([]);
    // const size = useContext(ResponsiveContext);

    useEffect(() => {
        const fetchBoxscore = async () => {
            const currentYear = new Date().getFullYear();
            const data = await getCurrentBoxscore(props.leagueID, currentYear, props.teamID);
            // console.log(data);
            setTeamInfo(data.team);
            setMatchupStats(data.boxscore);
        }
        fetchBoxscore();
    }, [props.leagueID, props.teamID]);

    const focusHome = teamId => {
        if (matchupStats.home.teamId === teamId) {
            return "dark-3"
        } else {
            return null
        }
    }

    const focusAway = teamId => {
        if (matchupStats.away.teamId === teamId) {
            return "dark-3"
        } else {
            return null
        }
    }

    const recordString = record => {
        if (record.ties > 0) {
            return `${record.wins}-${record.losses}-${record.ties}`;
        } else {
            return `${record.wins}-${record.losses}`;
        }
    }


    return (
        <Card>
            <CardHeader pad="medium">
                <Box direction="row" pad="small">
                    <Avatar size="small" src={teamInfo.logo ? teamInfo.logo : null} />
                    <Text>{teamInfo.id ? `${teamInfo.name}` : "...loading"}</Text>
                </Box>
                <Box direction="row" pad="small">
                    <Text>{teamInfo.record ? `${recordString(teamInfo.record.overall)}` : "...loading"}</Text>
                </Box>
            </CardHeader>
            <CardBody pad="medium">
                <Box direction="row">
                    <Box background={teamInfo.id ? focusHome(teamInfo.id) : null}>
                        <RosterTable team={matchupStats.home}/>
                    </Box>
                    <Box background={teamInfo.id ? focusAway(teamInfo.id) : null}>
                        <RosterTable team={matchupStats.away} />
                    </Box>
                </Box>
            </CardBody>
        </Card>
    );
}