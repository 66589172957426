const sum = (items, prop) => {
    return items.reduce((a, b) => {
        return a + b[prop];
    }, 0);
  };

export const totalScore = team => {
    if (team && team.rosterForCurrentScoringPeriod) {
      const starters = team.rosterForCurrentScoringPeriod.entries
                          .filter(entry => entry.lineupSlotId !== 20 && entry.lineupSlotId !== 21)
                          .map(player => player.playerPoolEntry);
      return sum(starters, "appliedStatTotal");
    } else {
      return '...loading'
    }
  };

  export const projectedPoints = team => {
    console.log(team);
    // Get the list of players (entries)
    const players = team.rosterForCurrentScoringPeriod.entries;
  
    // Filter players who don't have lineupSlotId of 20 or 21 (inactive players)
    const eligiblePlayers = players.filter(player => player.lineupSlotId !== 20 && player.lineupSlotId !== 21);
  
    // Sum up the total points (appliedStatTotal) of eligible players
    const totalPoints = eligiblePlayers.reduce((sum, player) => {
      const playerStats = player.playerPoolEntry.player.stats;
  
      // Check if the game has completed based on statSourceId
      const isGameComplete = playerStats[0].statSourceId === 0;
  
      // If the game is complete, use the first stat entry (appliedTotal), otherwise use the second stat entry (projection)
      const playerProjection = isGameComplete
        ? playerStats[0].appliedTotal // Final score if game is complete
        : (playerStats.length > 1 ? playerStats[1].appliedTotal : playerStats[0].appliedTotal); // Projection if game is ongoing or hasn't started
  
      return sum + (playerProjection || 0); // Handle case where appliedTotal might be undefined
    }, 0);
  
    return totalPoints;
  };